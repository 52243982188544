import MDBox from "components/MDBox";
import Footer from "common/Footer";
import { useQuery } from "react-query";
import rootApi from "services/baseAPI/WrapperApi";
import HttpService from "services/htttp.service";
import BalanceInfo from "./components/BalanceInfo";
import Transactions, { NoData } from "./components/Transactions";
import { useParams } from "react-router-dom";
import CardInfo from "./components/CardInfo";
import { formatCurrency } from "helpers/function";
import Loading from "components/MDLoading";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import { useEffect, useState } from "react";
import Search from "./components/Search";
import { showErrorAlert } from "helpers/function";
import MDTypography from "components/MDTypography";
import { Stack } from "@mui/material";
import { getTimeDifference } from "helpers/function";
import moment from "moment";
function Card() {
  const { id } = useParams();
  const {
    data: dataCardInfo,
    isLoading,
    refetch,
    isError,
    isSuccess,
    remove,
  } = useQuery(["admin-get-all-users"], () =>
    HttpService.get(`/rfids/search?serial_number=${id}`)
  );
  const dataTransactions = dataCardInfo?.data?.transactions?.map((item) => ({
    ...item,
    time: getTimeDifference(
      new Date(item?.start_time * 1000),
      new Date(item?.stop_time * 1000)
    ),
    charging_cost: `- ${formatCurrency(item?.charging_cost)} EVC`,
    color: "success",
  }));
  const dataDeposits = dataCardInfo?.data?.deposit_histories?.map((item) => ({
    color: "success",
    title: "Nạp tiền tài khoản",
    time: moment(new Date(item?.created_timestamp * 1000)).format(
      "DD/MM/YYYY HH:mm:ss"
    ),
    amount: `${formatCurrency(item?.amount)} EVC`,
    payment_method: item?.payment_method
  }));
  const dataActiveTransactions = dataCardInfo?.data?.active_transaction?.[0];
  useEffect(() => {
    remove();
    refetch();
  }, [id]);

  return (
    <>
      {isLoading ? (
        <MDBox mt={20} mb={50}>
          <Loading />
        </MDBox>
      ) : (
        <>
          <MDBox mt={2} mb={5}>
            {dataCardInfo?.data ? (
              <>
                <MDBox mb={2}>
                  <BalanceInfo
                    amount={`${formatCurrency(dataCardInfo?.data?.amount)} EVC`}
                    transferInfo={dataCardInfo?.data?.transfer_code_response}
                  />
                </MDBox> 
                <MDBox>
                  <CardInfo dataActiveTransactions={dataActiveTransactions}/>
                </MDBox>
                <MDBox mb={3}>
                  <Transactions
                    dataTransactions={dataTransactions}
                    dataDeposits={dataDeposits}
                  />
                </MDBox>
              </>
            ) : (
              <NoData
                text={`Không có thông tin thẻ, vui lòng kiểm tra lại!`}
                mt={20}
              />
            )}
          </MDBox>
        </>
      )}
    </>
  );
}

export default Card;
