
// Material Dashboard 2 React layouts
import Card from "layouts/card";

// @mui icons
import Icon from "@mui/material/Icon";
import Home from "layouts/home";

const routes = [
  {
    type: "collapse",
    name: "Home",
    key: "home",
    icon: <Icon fontSize="small">home</Icon>,
    route: "/home",
    component: <Home />,
  },
  {
    type: "collapse",
    name: "Card",
    key: "card",
    icon: <Icon fontSize="small">receipt_long</Icon>,
    route: "/:id",
    component: <Card />,
  },
];

export default routes;
