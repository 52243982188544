import { LocationOn, LocalPhone } from "@mui/icons-material";
import { Paper, Typography, colors, styled } from "@mui/material";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Stack from "@mui/material/Stack";
import EverChargeLogo from "assets/images/logos/ever-charge.svg";
import FacebookLogo from "assets/images/logos/Facebook.svg";
import ZaloLogo from "assets/images/logos/Zalo.svg";
import custLogo from "assets/images/logos/Cust.svg";
import EmailLogo from "assets/images/logos/Email.svg";

// Material Kit 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

const Box = styled("div")({
  // position: "inherit",
  bottom: 0,
  width: "100%",
});

function Footer() {
  return (
    <Box>
      <MDBox component="footer">
        <Paper>
          <Container>
            <Grid container ml={{ xs: 0, md: 10 }}>
              <Grid
                item
                xs={12}
                md={4}
                mb={{ xs: 3, lg: 0 }}
                mt={{xs: 1, md: 3}}
              >
                <MDBox
                  component="img"
                  src={EverChargeLogo}
                  alt="EverCharge logo"
                  width="50%"
                />
                <MDTypography
                  variant="h6"
                  textTransform="uppercase"
                  sx={{ fontSize: "20px" }}
                >
                  CÔNG TY TNHH EVERCHARGE
                </MDTypography>
                <MDTypography
                  variant="p"
                  textTransform="uppercase"
                  mb={{ xs: 2, md: 4 }}
                  sx={{ fontSize: "16px" }}
                >
                  Xanh - Nhanh - Thông Minh
                </MDTypography>
                <Stack direction="row" gap={1} mt={1}>
                  <LocationOn sx={{ fontSize: "16px", color: "#2D9764" }} />
                  <MDTypography
                    variant="p"
                    mb={{ xs: 1, lg: 1 }}
                    sx={{ fontSize: "16px" }}
                  >
                    Tầng 4, khu nhà Rainbow (đảo Cầu Vồng), phố Thạch Bàn,
                    phường Thạch Bàn, quận Long Biên, TP Hà Nội
                  </MDTypography>
                </Stack>
                <Stack direction="row" gap={1}>
                  <LocalPhone sx={{ fontSize: "16px", color: "#2D9764" }} />
                  <MDTypography
                    variant="p"
                    mb={{ xs: 2, lg: 3 }}
                    sx={{ fontSize: "16px" }}
                  >
                    +84 (0) 19001570 - +84 (1) 19001577
                  </MDTypography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={4} mb={{ xs: 3, lg: 0 }} mt={{xs: 1, md: 3}}>
                <Stack direction="column" gap={1}>
                  <MDTypography
                    variant="h6"
                    sx={{ fontSize: "20px", fontWeight: "600" }}
                  >
                    Truy cập nhanh
                  </MDTypography>
                  <MDTypography
                    component={Link}
                    href="https://evercharge.vn/#about"
                    target="_blank"
                    rel="noreferrer"
                    variant="body2"
                    color="dark"
                  >
                    Về chúng tôi
                  </MDTypography>
                  <MDTypography
                    component={Link}
                    href="https://evercharge.vn/#product"
                    target="_blank"
                    rel="noreferrer"
                    variant="body2"
                    color="dark"
                  >
                    Sản phẩm nổi bật
                  </MDTypography>
                  <MDTypography
                    component={Link}
                    href="https://evercharge.vn/#contact"
                    target="_blank"
                    rel="noreferrer"
                    variant="body2"
                    color="dark"
                  >
                    Liên hệ
                  </MDTypography>
                  <MDTypography
                    component={Link}
                    href="https://mo.evercharge.vn/"
                    target="_blank"
                    rel="noreferrer"
                    variant="body2"
                    color="dark"
                  >
                    Đăng nhập
                  </MDTypography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={4} mb={{ xs: 3, lg: 0 }} mt={{xs: 1, md: 3}}>
                <Stack direction="column" gap={1}>
                  <MDTypography
                    variant="h6"
                    sx={{ fontSize: "20px", fontWeight: "600" }}
                  >
                    Hỗ trợ khách hàng
                  </MDTypography>
                  <Stack direction="row" gap={1}>
                    <MDBox
                      component="img"
                      src={FacebookLogo}
                      alt="EverCharge logo"
                      width="5%"
                    />
                    <MDTypography
                      component={Link}
                      href="https://www.facebook.com/evercharge.sacxedienthongminh"
                      target="_blank"
                      rel="noreferrer"
                      variant="body2"
                      color="dark"
                    >
                      Fanpage - EverCharge
                    </MDTypography>
                  </Stack>
                  <Stack direction="row" gap={1}>
                    <MDBox
                      component="img"
                      src={ZaloLogo}
                      alt="EverCharge logo"
                      width="5%"
                    />
                    <MDTypography
                      component={Link}
                      href="https://zalo.me/g/ixbagw399"
                      target="_blank"
                      rel="noreferrer"
                      variant="body2"
                      color="dark"
                    >
                      Zalo - EverCharge
                    </MDTypography>
                  </Stack>
                  <Stack direction="row" gap={1}>
                    <MDBox
                      component="img"
                      src={custLogo}
                      alt="EverCharge logo"
                      width="5%"
                    />
                    <MDTypography
                      target="_blank"
                      rel="noreferrer"
                      variant="body2"
                      color="dark"
                    >
                      Hotline - 0977941596
                    </MDTypography>
                  </Stack>
                  <Stack direction="row" gap={1}>
                    <MDBox
                      component="img"
                      src={EmailLogo}
                      alt="EverCharge logo"
                      width="5%"
                    />
                    <MDTypography
                      target="_blank"
                      rel="noreferrer"
                      variant="body2"
                      color="dark"
                    >
                      support@evercharge.vn
                    </MDTypography>
                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Container>
          <Paper sx={{ backgroundColor: "#2D9764" }}>
            <MDBox display="flex" justifyContent="center" pt={1} pb={1}>
              <MDTypography
                target="_blank"
                rel="noreferrer"
                variant="body2"
                color="white"
              >
                EverCharge. All rights reserved. © Copyright{" "}
                {`${new Date().getFullYear()}`}{" "}
              </MDTypography>
            </MDBox>
          </Paper>
        </Paper>
      </MDBox>
    </Box>
  );
}

export default Footer;
