// react-router components
import { Routes, Route, Navigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "assets/theme";
import routes from "routes";
import ProtectedRoute from "common/ProtectedRoute";
import { QueryClient, QueryClientProvider } from "react-query";
import DashboardNavbar from "common/Navbars/DashboardNavbar";
import DashboardLayout from "common/LayoutContainers/DashboardLayout";
import Footer from "common/Footer";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: 0,
      cacheTime: 0,
    },
  },
});

export default function App() {
  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route && route.type !== "auth") {
        return (
          <Route
            exact
            path={route.route}
            element={
              <ProtectedRoute isAuthenticated={true}>
                {route.component}
              </ProtectedRoute>
            }
            key={route.key}
          />
        );
      }
      return null;
    });
  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <DashboardNavbar />
          <DashboardLayout>
            <Routes>
              {getRoutes(routes)}
              <Route path="*" element={<Navigate to="/home" />} />
            </Routes>
          </DashboardLayout>
          <Footer />
        </ThemeProvider>
      </QueryClientProvider>
    </>
  );
}
