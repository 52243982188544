import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { useState } from "react";

function ChargingSession({ color, icon, power, time, charging_cost, dateCharge }) {
  
  return (
    <MDBox key={name} component="li" py={2} px={1}>
      <MDBox display="flex" justifyContent="space-between" alignItems="center">
        <MDBox display="flex" alignItems="center">
          <MDBox mr={2}>
            <MDButton variant="outlined" color={color} iconOnly circular>
              <Icon sx={{ fontWeight: "bold" }}>{icon}</Icon>
            </MDButton>
          </MDBox>
          <MDBox display="flex" flexDirection="column">
            <MDTypography variant="button" fontWeight="medium" gutterBottom>
              {`${power ?? 0} KWh`}
            </MDTypography>
            <MDTypography variant="caption" color="text" fontWeight="regular">
              {time}
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox display="flex" flexDirection="column" alignItems='flex-end'>
        <MDTypography variant="button" color={'error'} fontWeight="medium" textGradient>
          {charging_cost}
        </MDTypography>
        <MDTypography variant="caption" color="text" fontWeight="regular">
          {dateCharge}
        </MDTypography>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

// Typechecking props of the Transaction
ChargingSession.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]).isRequired,
  icon: PropTypes.node.isRequired,
  name: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
};

export default ChargingSession;
