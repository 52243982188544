import Swal from "sweetalert2";
import warningIcon from "assets/images/illustrations/warning-icon.svg";

export const showErrorAlert = (msg, text = "") => {
  return Swal.fire({
    icon: "error",
    title: msg.replace(/\[ERR_[0-9]+\]/g, ""),
    text: text.replace(/\[ERR_[0-9]+\]/g, ""),
  });
};

export const showWarning = (msg, text) => {
  return Swal.fire({
    imageUrl: warningIcon,
    title: msg.replace(/\[ERR_[0-9]+\]/g, ""),
    text: text.replace(/\[ERR_[0-9]+\]/g, ""),
    showCloseButton: true,
    showConfirmButton: false
  });
};

export const showWarning2 = (msg, text) => {
  return Swal.fire({
    icon: 'info',
    title: msg.replace(/\[ERR_[0-9]+\]/g, ""),
    text: text.replace(/\[ERR_[0-9]+\]/g, ""),
    showCloseButton: true,
    showConfirmButton: false
  });
};

export const showSuccessAlert = (msg, text = "") => {
  return Swal.fire({
    icon: "success",
    title: msg.replace(/\[ERR_[0-9]+\]/g, ""),
    text: text.replace(/\[ERR_[0-9]+\]/g, ""),
  });
};

export function isNullOrEmpty(value) {
  return value === undefined || value === null || value === "";
}

export function formatCurrency(money, digits) {
  if (isNullOrEmpty(money)) {
    return "0";
  }
  let format = "$1.";
  return `${digits ? money : Math.trunc(+money)}`.replace(
    /(\d)(?=(\d{3})+(?!\d))/g,
    format
  );
}
export function getTimeDifference(startTime, stopTime) {
  // Calculate the difference in milliseconds
  const timeDifference = stopTime - startTime;

  // Convert milliseconds to seconds
  let timeInSeconds = Math.floor(timeDifference / 1000);

  // Calculate days
  const days = Math.floor(timeInSeconds / (3600 * 24));
  timeInSeconds %= 3600 * 24;

  // Calculate hours
  const hours = Math.floor(timeInSeconds / 3600);
  timeInSeconds %= 3600;

  // Calculate minutes
  const minutes = Math.floor(timeInSeconds / 60);
  timeInSeconds %= 60;

  // The remaining seconds
  const seconds = timeInSeconds;

  return `Thời lượng: ${hours ? hours + ':' : '0:'}${minutes ? minutes + ':' :'0:'}${seconds ? seconds : '0'}`;
}