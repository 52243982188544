import React, { useState } from "react";
import {
  Button,
  Modal,
  Box,
  Typography,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  IconButton,
} from "@mui/material";
import QRCode from "qrcode.react";
import vietQRLogo from "assets/images/vietQR.png";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { formatCurrency } from "helpers/function";
import CloseIcon from "@mui/icons-material/Close";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import moment from "moment";
import { NoData } from "..";
function DepositDetailModal({ open, setOpen, deposit }) {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="sm">
        <DialogTitle>Chi tiết giao dịch</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {deposit ? (
            <>
              <Stack direction={"column"} spacing={1}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  spacing={2}
                  sx={{ fontSize: "14px", fontWeight: 400 }}
                >
                  <MDTypography variant="p" color="text" fontWeight="regular">
                    Hình thức TT:
                  </MDTypography>
                  <MDTypography variant="p" fontWeight="regular">
                    {deposit?.payment_method}
                  </MDTypography>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  spacing={2}
                  sx={{ fontSize: "14px", fontWeight: 400 }}
                >
                  <MDTypography variant="p" color="text" fontWeight="regular">
                    Số tiền:
                  </MDTypography>
                  <MDTypography variant="p" fontWeight="regular">
                    {deposit?.amount}
                  </MDTypography>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  spacing={2}
                  sx={{ fontSize: "14px", fontWeight: 400 }}
                >
                  <MDTypography variant="p" color="text" fontWeight="regular">
                    Thời gian:
                  </MDTypography>
                  <MDTypography variant="p" fontWeight="regular">
                    {deposit?.time}
                  </MDTypography>
                </Stack>
              </Stack>
            </>
          ) : (
            <NoData text={"Không có dữ liệu, vui lòng liên hệ ban quản trị!"} />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default DepositDetailModal;
