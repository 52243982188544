import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import { showWarning } from "helpers/function";
import { useState } from "react";
import QRCodeModal from "./QRCodeModal";

function BalanceInfo({amount, limitAmount, transferInfo}) {

  const [isOpenModalQR, setIsOpenModalQR] = useState(false);

  const handleReCharge = () => {
    // showWarning('Thông báo', 'Tính năng này đang phát triển. Vui lòng truy cập lại sau!');
    setIsOpenModalQR(true);
  }

  return (
    <>
    <Card id="delete-account">
      <MDBox
        pt={2}
        pb={2}
        px={2}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
      >
        <MDBox>
        <MDBox display="flex">
          <MDTypography
            variant="body"
            fontWeight="medium"
            sx={{ color: "#2D9764", fontSize: '20px' }}
          >
            {amount}
          </MDTypography>
          {/* <MDTypography
            variant="body"
            fontWeight="medium"
            sx={{ fontSize: '20px' }}
          >
            /
          </MDTypography>
          <MDTypography
            variant="body"
            fontWeight="medium"
            sx={{ fontSize: '20px' }}
          >
            {limitAmount} đ
          </MDTypography> */}
        </MDBox>
        <MDTypography
            variant="body"
            sx={{ fontSize: '14px' }}
          >
            Số tiền khả dụng
          </MDTypography>
        </MDBox>
        <MDButton variant="gradient" sx={{ backgroundColor: "#2D9764", color: "#FFFF" }} onClick={handleReCharge}>
          <Icon sx={{ fontWeight: "bold" }}>add</Icon>
          Nạp tiền
        </MDButton>
      </MDBox>
    </Card>
    <QRCodeModal setOpen={setIsOpenModalQR} open={isOpenModalQR} transferInfo={transferInfo}/>
    </>
    
  );
}

export default BalanceInfo;
