import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
// Material Dashboard 2 React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

import carCharging from "assets/images/illustrations/car-charging.svg";
import charging from "assets/images/illustrations/charging.svg";
import { Grid, Stack } from "@mui/material";
import BatteryCharging90Icon from "@mui/icons-material/BatteryCharging90";
import MDButton from "components/MDButton";
import EvStationOutlinedIcon from "@mui/icons-material/EvStationOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import { TimerOutlined } from "@mui/icons-material";
import { getTimeDifference } from "helpers/function";

function CardInfo({ color = "#FA5050", dataActiveTransactions }) {
  return (
    <>
      {dataActiveTransactions ? (
        <Card
          sx={({
            palette: { gradients },
            functions: { linearGradient },
            boxShadows: { xl },
          }) => ({
            background: gradients[color]
              ? linearGradient(gradients[color].main, gradients[color].state)
              : linearGradient(gradients.dark.main, gradients.dark.state),
            boxShadow: xl,
            position: "relative",
            height: 300,
          })}
        >
          <MDBox
            position="absolute"
            component="img"
            src={carCharging}
            bottom={0}
            left={0}
            width="60%"
            height="60%"
            opacity={0.2}
            mb={3}
          />
          <MDBox position="relative" zIndex={2} p={2}>
            <Stack
              direction="row"
              justifyContent="space-around"
              alignItems="center"
              spacing={2}
            >
              <Stack
                direction={{ xs: "column", lg: "row" }}
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                <MDBox
                  component="img"
                  src={charging}
                  alt="Charging icon"

                  justifyContent="center"
                  alignItems="center"
                  position="relative"
                  sx={{ width: 80, height: 80 }}
                />
                <MDTypography
                  variant="h2"
                  color="white"
                  fontWeight="medium"
                  pt={1.5}
                  ml={2}
                  sx={{ font: "32px" }}
                >
                  {`${dataActiveTransactions?.soc ?? 0} %`}
                </MDTypography>
              </Stack>
              <MDBox display="block" pt={4} pr={2}>
                <MDBox display="flex" mb={2}>
                  <MDBox color="white" lineHeight={0} display="inline-block">
                    <EvStationOutlinedIcon sx={{ width: 32, height: 32 }} />
                  </MDBox>
                  <MDTypography
                    fontSize="large"
                    variant="text"
                    color="white"
                    fontWeight="regular"
                    ml={1}
                  >
                    {`${dataActiveTransactions?.power ?? 0} Kwh`}
                  </MDTypography>
                </MDBox>
                {/* <MDBox display="flex" mb={2}>
                  <MDBox color="white" lineHeight={0} display="flex-start">
                    <MonetizationOnOutlinedIcon
                      sx={{ width: 32, height: 32 }}
                    />
                  </MDBox>
                  <MDTypography
                    fontSize="large"
                    variant="text"
                    color="white"
                    fontWeight="regular"
                    ml={1}
                  >
                    {`${dataActiveTransactions?.charging_cost ?? 0} EVC`}
                  </MDTypography>
                </MDBox> */}
                <MDBox display="flex" mb={2}>
                  <MDBox color="white" lineHeight={0} display="inline-block">
                    <TimerOutlined sx={{ width: 32, height: 32 }} />
                  </MDBox>
                  <MDTypography
                    fontSize="large"
                    variant="text"
                    color="white"
                    fontWeight="regular"
                    ml={1}
                  >
                    {`${getTimeDifference(
                      new Date(dataActiveTransactions?.start_time * 1000),
                      new Date(dataActiveTransactions?.stop_time * 1000)
                    )}`}
                  </MDTypography>
                </MDBox>
              </MDBox>
            </Stack>
          </MDBox>
        </Card>
      ) : (
        <></>
      )}
    </>
  );
}

CardInfo.defaultProps = {
  color: "dark",
};

CardInfo.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
  ]),
  number: PropTypes.number.isRequired,
  holder: PropTypes.string.isRequired,
  expires: PropTypes.string.isRequired,
};

export default CardInfo;
