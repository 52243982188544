import { useState, useEffect, useContext } from "react";
import { useLocation, Link, useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import MDBox from "components/MDBox";
import MDInput from "components/MDInput";
import {
  navbar,
  navbarContainer,
  navbarRow,
} from "common/Navbars/DashboardNavbar/styles";
import { useMaterialUIController, setTransparentNavbar } from "context";
import MDButton from "components/MDButton";
import EverChargeLogo from "assets/images/logos/ever-charge.svg";
import EverChargeMiniLogo from "assets/images/illustrations/small_logo.svg";
import {
  Icon,
  IconButton,
  TextField,
  styled,
  useMediaQuery,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import { InputOutlined, Search } from "@mui/icons-material";
import { showWarning2 } from "helpers/function";

const RectangularIconButton = styled(IconButton)({
  borderRadius: "4px", // Adjust border radius as needed
  transition: "background-color 0.3s",
  "&:hover": {
    backgroundColor: "#2D9764",
  },
  "&:active": {
    backgroundColor: "#2D9764",
  },
});

function DashboardNavbar({ light }) {
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const [idCard, setIdCard] = useState("");
  let navigate = useNavigate();
  const location = useLocation();

  const themeX = useTheme();
  const isMobile = useMediaQuery(themeX.breakpoints.down("sm"));

  const fixedNavbar = true;

  const handleSearch = async () => {
    if (!idCard) {
      showWarning2("Thông báo", "Vui lòng nhập số thẻ để tra cứu!");
      return;
    }
    navigate(`/${idCard}`);
  };
  
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  }

  useEffect(() => {
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }
    function handleTransparentNavbar() {
      setTransparentNavbar(
        dispatch,
        (fixedNavbar && window.scrollY === 0) || !fixedNavbar
      );
    }

    window.addEventListener("scroll", handleTransparentNavbar);
    handleTransparentNavbar();
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  return (
    <AppBar position={navbarType} color="inherit" sx={{ width: "100%" }}>
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox
          color="inherit"
          mb={{ xs: 1, md: 0 }}
          ml={{ xs: 0, md: 8 }}
          sx={(theme) => navbarRow(theme, { isMini: false })}
        >
          <MDBox
            component="img"
            src={isMobile ? EverChargeMiniLogo : EverChargeLogo}
            alt="EverCharge logo"
            width={isMobile ? "10%" : "60%"}
            mt={1}
          />
          {isMobile && (
            <>
              {location?.pathname != "/home" && (
                <MDBox mt={1}>
                  <TextField
                    sx={{ marginRight: "5px" }}
                    placeholder="Nhập số thẻ để tra cứu..."
                    value={idCard}
                    onChange={(val) => setIdCard(val?.target?.value)}
                    onKeyDown={handleKeyDown}
                  />
                  <RectangularIconButton
                    sx={{
                      color: "#FFFF",
                      backgroundColor: "#2D9764",
                      marginTop: "2px",
                    }}
                    aria-label="directions"
                    onClick={handleSearch}
                  >
                    <Search />
                  </RectangularIconButton>
                </MDBox>
              )}
            </>
          )}
        </MDBox>
        {!isMobile && (
          <>
            {location?.pathname != "/home" && (
              <MDBox
                mr={{ xs: 0, md: 8 }}
                sx={(theme) => navbarRow(theme, { isMini: false })}
              >
                <MDBox pr={1}>
                  <MDInput
                    fullWidth
                    label="Nhập số thẻ để tra cứu..."
                    onChange={(val) => setIdCard(val?.target?.value)}
                    value={idCard}
                    onKeyDown={handleKeyDown}
                  />
                </MDBox>
                <MDBox
                  display="flex"
                  alignItems="center"
                  color={light ? "white" : "inherit"}
                >
                  <MDBox>
                    <MDButton
                      sx={{ backgroundColor: "#2D9764", color: "#FFFF" }}
                      variant="gradient"
                      fullWidth
                      type="button"
                      onClick={handleSearch}
                    >
                      <Icon sx={{ fontWeight: "bold" }}>search</Icon>
                      Tìm kiếm
                    </MDButton>
                  </MDBox>
                </MDBox>
              </MDBox>
            )}
          </>
        )}
      </Toolbar>
    </AppBar>
  );
}

DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
};
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
};

export default DashboardNavbar;
