import React, { useState } from "react";
import {
  Button,
  Modal,
  Box,
  Typography,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  IconButton,
} from "@mui/material";
import QRCode from "qrcode.react";
import vietQRLogo from "assets/images/vietQR.png";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { formatCurrency } from "helpers/function";
import CloseIcon from "@mui/icons-material/Close";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import moment from "moment";
import { NoData } from "..";
function ChargingSessionDetailModal({ open, setOpen, chargingInfo }) {
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="sm">
        <DialogTitle>Chi tiết phiên sạc</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {chargingInfo ? (
            <>
              <Stack direction={"column"} spacing={1}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  spacing={2}
                  sx={{fontSize: '14px', fontWeight: 400}}
                >
                  <MDTypography variant="p" color="text" fontWeight="regular">
                    Mã trạm:
                  </MDTypography>
                  <MDTypography variant="p" fontWeight="regular">
                    {chargingInfo?.identifier ?? '-'}
                  </MDTypography>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  spacing={2}
                  sx={{fontSize: '14px', fontWeight: 400}}
                >
                  <MDTypography variant="p" color="text" fontWeight="regular">
                    Phí sạc:
                  </MDTypography>
                  <MDTypography variant="p" fontWeight="regular">
                  {chargingInfo?.charging_cost}
                  </MDTypography>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  spacing={2}
                  sx={{fontSize: '14px', fontWeight: 400}}
                >
                  <MDTypography variant="p" color="text" fontWeight="regular">
                    Công suất:
                  </MDTypography>
                  <MDTypography variant="p" fontWeight="regular">
                  {`${chargingInfo?.power} Kwh`}
                  </MDTypography>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  spacing={2}
                  sx={{fontSize: '14px', fontWeight: 400}}
                >
                  <MDTypography variant="p" color="text" fontWeight="regular">
                    Trạng thái:
                  </MDTypography>
                  <MDTypography variant="p" fontWeight="regular">
                    {`${chargingInfo?.status ?? '-'}`}
                  </MDTypography>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  spacing={2}
                  sx={{fontSize: '14px', fontWeight: 400}}
                >
                  <MDTypography variant="p" color="text" fontWeight="regular">
                    Thời gian bắt đầu:
                  </MDTypography>
                  <MDTypography variant="p" fontWeight="regular">
                    {moment(new Date(chargingInfo?.start_time * 1000)).format('DD/MM/YYYY HH:mm:ss')}
                  </MDTypography>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  spacing={2}
                  sx={{fontSize: '14px', fontWeight: 400}}
                >
                  <MDTypography variant="p" color="text" fontWeight="regular">
                    Thời gian kết thúc:
                  </MDTypography>
                  <MDTypography variant="p" fontWeight="regular">
                    {moment(new Date(chargingInfo?.stop_time * 1000)).format('DD/MM/YYYY HH:mm:ss')}
                  </MDTypography>
                </Stack>
              </Stack>
            </>
          ) : (
            <NoData text={"Không có dữ liệu, vui lòng liên hệ ban quản trị!"} />
          )}
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default ChargingSessionDetailModal;
