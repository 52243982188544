import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Stack,
  IconButton,
} from "@mui/material";
import QRCode from "qrcode.react";
import vietQRLogo from "assets/images/vietQR.png";
import EverChargeLogo from "assets/images/logos/EverChargeMiniLogo.png";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { NoData } from "../Transactions";
import CloseIcon from "@mui/icons-material/Close";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { ContentCopy } from "@mui/icons-material";
function QRCodeModal({ open, setOpen, transferInfo }) {
  const handleClose = () => {
    setOpen(false);
  };
  const downloadQRCode = () => {
    const canvas = document.getElementById("qr-canvas");
    const url = canvas.toDataURL("image/png");
    const link = document.createElement("a");
    link.download = `QRCode_${transferInfo?.transfer_code}.png`;
    link.href = url;
    link.click();
  };
  return (
    <div>
      <Dialog open={open} onClose={handleClose} maxWidth="sm">
        <DialogTitle>Nạp thẻ</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {transferInfo ? (
            <>
              <Stack
                direction={"column"}
                justifyContent={"center"}
                alignItems={"center"}
                spacing={1}
                marginBottom={1}
              >
                {transferInfo?.qr_code && (
                  <>
                    <MDTypography
                      variant="p"
                      color="text"
                      fontWeight="regular"
                      pb={2}
                      sx={{ fontSize: "14px", fontWeight: 400 }}
                    >
                      Vui lòng quét mã QR để chuyển khoản
                    </MDTypography>
                    <MDBox
                      component="img"
                      src={vietQRLogo}
                      alt="Viet QR logo"
                      width="20%"
                      height="20%"
                      justifyContent="center"
                      alignItems="center"
                    />
                    <QRCode
                      id="qr-canvas"
                      value={transferInfo?.qr_code}
                      size={200}
                      imageSettings={{
                        src: EverChargeLogo,
                        excavate: true,
                        width: 32,
                        height: 32,
                      }}
                    />
                  </>
                )}
              </Stack>
              <MDTypography
                variant="h6"
                fontWeight="medium"
                textTransform="capitalize"
                py={2}
              >
                Thông tin chuyển khoản
              </MDTypography>
              <Stack direction={"column"} spacing={1}>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  spacing={2}
                  sx={{ fontSize: "14px", fontWeight: 400 }}
                >
                  <MDTypography variant="p" color="text" fontWeight="regular">
                    Ngân hàng:
                  </MDTypography>
                  <MDTypography variant="p" fontWeight="regular">
                    Vietinbank
                  </MDTypography>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  spacing={2}
                  sx={{ fontSize: "14px", fontWeight: 400 }}
                >
                  <MDTypography variant="p" color="text" fontWeight="regular">
                    Số tài khoản:
                  </MDTypography>

                  <Stack direction={"row"} alignItems={"center"}>
                    <MDTypography variant="p" fontWeight="regular">
                      111614656688
                    </MDTypography>
                    <IconButton
                      aria-label="close"
                      onClick={() => {
                        navigator.clipboard.writeText("111614656688");
                      }}
                      sx={{
                        marginLeft: "5px",
                        color: "#2D9764",
                        width: "20px",
                        height: "20px",
                      }}
                    >
                      <ContentCopy />
                    </IconButton>
                  </Stack>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  spacing={2}
                  sx={{ fontSize: "14px", fontWeight: 400 }}
                >
                  <MDTypography variant="p" color="text" fontWeight="regular">
                    Chủ tài khoản:
                  </MDTypography>
                  <MDTypography variant="p" fontWeight="regular">
                    CONG TY CP NANG LUONG EVERCHARGE
                  </MDTypography>
                </Stack>
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  alignItems="flex-start"
                  spacing={2}
                  sx={{ fontSize: "14px", fontWeight: 400 }}
                >
                  <MDTypography variant="p" color="text" fontWeight="regular">
                    Nội dung:
                  </MDTypography>
                  <Stack direction={"row"} alignItems={"center"}>
                    <MDTypography variant="p" fontWeight="regular">
                      {transferInfo?.transfer_code}
                    </MDTypography>
                    <IconButton
                      aria-label="copy noi dung"
                      onClick={() => {
                        navigator.clipboard.writeText(
                          transferInfo?.transfer_code
                        );
                      }}
                      sx={{
                        marginLeft: "5px",
                        color: "#2D9764",
                        width: "20px",
                        height: "20px",
                      }}
                    >
                      <ContentCopy />
                    </IconButton>
                  </Stack>
                </Stack>
              </Stack>
            </>
          ) : (
            <NoData text={"Không có dữ liệu, vui lòng liên hệ ban quản trị!"} />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            sx={{ mr: 1, color: "#FFFF", backgroundColor: "#2D9764" }}
            onClick={downloadQRCode}
          >
            <FileDownloadIcon />
            Tải QR code
          </Button>
          <Button onClick={handleClose} variant="contained" color="error">
            Đóng
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default QRCodeModal;
