import { Divider } from "@mui/material";
import Card from "@mui/material/Card";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import { useState } from "react";
import ChargingSession from "./components/ChargingSession";
import Recharge from "./components/Recharge";
import NoDataImg from "assets/images/no_data.svg";
import moment from "moment";
import ChargingSessionDetailModal from "./components/ChargingSessionDetailModal";
import DepositDetailModal from "./components/DepositDetailModal";

export const NoData = ({ text, mt }) => {
  return (
    <>
      <MDBox
        mt={mt ? mt : 10}
        p={2}
        mx={3}
        display="flex"
        justifyContent="center"
      >
        <MDBox
          component="img"
          src={NoDataImg}
          alt="No data"
          width="20%"
          justifyContent="center"
          alignItems="center"
          position="relative"
        />
      </MDBox>
      <MDBox
        mb={mt ? mt : 0}
        p={1}
        mx={1}
        display="flex"
        justifyContent="center"
      >
        <MDTypography display="inline" variant="body1">
          {text}
        </MDTypography>
      </MDBox>
    </>
  );
};

function Transactions({ dataDeposits, dataTransactions }) {
  const [isChargingSession, setIsChargingSession] = useState(true);
  const [isOpenChargingSession, setIsOpenChargingSession] = useState(false);
  const [isOpenDeposit, setIsOpenDeposit] = useState(false);
  const [itemChargingDetail, setItemChargingDetail] = useState('');
  const [itemDepositDetail, setItemDepositDetail] = useState('');
  return (
    <>
      <MDBox>
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          pt={3}
          pb={2}
        >
          <MDTypography
            variant="h6"
            fontWeight="medium"
            textTransform="capitalize"
          >
            Hoạt động gần đây
          </MDTypography>
          <MDBox display="flex" alignItems="flex-start">
            <MDTypography
              pr={2}
              variant="button"
              color={isChargingSession ? "success" : "text"}
              fontWeight={isChargingSession ? "medium" : "regular"}
              sx={{ cursor: "pointer" }}
              onClick={() => setIsChargingSession(true)}
            >
              Phiên sạc
            </MDTypography>
            <MDTypography
              variant="button"
              color={!isChargingSession ? "success" : "text"}
              fontWeight={!isChargingSession ? "medium" : "regular"}
              sx={{ cursor: "pointer" }}
              onClick={() => setIsChargingSession(false)}
            >
              Nạp tiền
            </MDTypography>
          </MDBox>
        </MDBox>
        <MDBox pt={1} pb={2}>
          <MDBox
            component="ul"
            display="flex"
            flexDirection="column"
            p={0}
            m={0}
            sx={{ listStyle: "none" }}
          >
            {isChargingSession ? (
              <>
                {dataTransactions?.length ? (
                  dataTransactions?.map((item) => (
                    <>
                      <Card
                        sx={{
                          width: "100%",
                          height: "100%",
                          marginBottom: "20px",
                          cursor: "pointer"
                        }}
                        onClick={() => {
                          setIsOpenChargingSession(true)
                          setItemChargingDetail(item);
                        }}
                      >
                        <ChargingSession
                          color={item?.color}
                          icon="electrical_services"
                          power={item?.power}
                          time={item?.time}
                          charging_cost={item?.charging_cost}
                          dateCharge={moment(
                            new Date(item?.start_time * 1000)
                          ).format("DD/MM/YYYY HH:mm:ss")}
                        />
                      </Card>
                    </>
                  ))
                ) : (
                  <NoData text={`Không có dữ liệu`} />
                )}
              </>
            ) : (
              <>
                {dataDeposits?.length ? (
                  dataDeposits?.map((item) => (
                    <>
                      <Card
                        sx={{
                          width: "100%",
                          height: "100%",
                          marginBottom: "20px",
                          cursor: "pointer"
                        }}
                        onClick={() => {
                          setIsOpenDeposit(true)
                          setItemDepositDetail(item);
                        }}
                      >
                        <Recharge
                          color={item?.color}
                          icon="payment_icon"
                          title={item?.title}
                          time={item?.time}
                          amount={item?.amount}
                        />
                      </Card>
                    </>
                  ))
                ) : (
                  <NoData text={`Không có dữ liệu`} />
                )}
              </>
            )}
          </MDBox>
        </MDBox>
      </MDBox>
      <ChargingSessionDetailModal open={isOpenChargingSession} setOpen={setIsOpenChargingSession} chargingInfo={itemChargingDetail}/>
      <DepositDetailModal open={isOpenDeposit} setOpen={setIsOpenDeposit} deposit={itemDepositDetail}/>
    </>
  );
}

export default Transactions;
