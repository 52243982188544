import MDBox from "components/MDBox";
import HomeBackGround from "assets/images/home_bg.png";
import MDTypography from "components/MDTypography";
import { Card, IconButton, InputBase, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { showWarning } from "helpers/function";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";

function Home() {
  const navigate = useNavigate();
  const [idCard, setIdCard] = useState("");
  const handleSearch = () => {
    if (!idCard) {
      showWarning("Vui lòng nhập mã thẻ của bạn!");
      return;
    }
    navigate(`/${idCard}`);
  };
  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      handleSearch();
    }
  }
  return (
    <>
      <MDBox mt={10}>
        <MDBox p={2} mx={3} display="flex" justifyContent="center">
          <MDBox
            component="img"
            src={HomeBackGround}
            alt="Home background"
            width="70%"
            height="70%"
            justifyContent="center"
            alignItems="center"
            position="relative"
          />
        </MDBox>
        <MDBox p={2} mx={3} display="flex" justifyContent="center">
          <MDTypography
            display="inline"
            variant="h3"
            fontWeight="bold"
            sx={{ color: "#2D9764" }}
          >
            Tra cứu thông tin thẻ
          </MDTypography>
        </MDBox>
        <MDBox p={1} mx={1} display="flex" justifyContent="center">
          <MDTypography display="inline" variant="p">
            Vui lòng nhập số thẻ của bạn để kiểm tra thông tin về số tiền khả
            dụng, lịch sử sạc xe, lịch sử nạp tiền
          </MDTypography>
        </MDBox>
        <MDBox p={1} pb={20} display="flex" justifyContent="center">
          <Paper
            component="form"
            sx={{
              p: "2px 4px",
              display: "flex",
              alignItems: "center",
              width: 600,
            }}
          >
            <InputBase
              fullWidth
              sx={{
                ml: 1,
                flex: 1,
                width: { sm: 400, md: 600 },
                fontSize: "22px",
              }}
              placeholder="Nhập số thẻ để tra cứu..."
              inputProps={{ "aria-label": "Nhập số thẻ để tra cứu..." }}
              value={idCard}
              onChange={(val) => setIdCard(val?.target?.value)}
              onKeyDown={handleKeyDown}
            />
            {idCard && (
              <IconButton
                sx={{ p: "10px", color: "#2D9764" }}
                aria-label="directions"
                onClick={handleSearch}
              >
                <ArrowCircleRightIcon sx={{ width: 32, height: 32 }} />
              </IconButton>
            )}
          </Paper>
        </MDBox>
      </MDBox>
    </>
  );
}

export default Home;
